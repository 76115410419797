import axios from "axios";
import { apiClient } from "./apiCore";
import { apiClient2 } from "./apiCore2";

const getVendedor = vendedor => {
  const baseUrl = `/api/seller/autocomplete/${vendedor}`;
  return apiClient.get(`${baseUrl}`);
};

const getOrdersList = filter => {
  const baseUrl = `/api/orders`;
  return apiClient.create(`${baseUrl}`, filter);
};

const getOrder = payload => {
  const baseUrl = `/api/orders/${payload.orderNum}/${
    payload.company ? payload.company : "01"
  }/${payload.filial}`;
  return apiClient.get(`${baseUrl}`);
};

const getOrderForCustomer = payload => {
  const baseUrl = `/api/orders/${payload.orderNum}/cpfcnpj/${payload.customerCpfCnpj}`;
  return apiClient.create(`${baseUrl}`, { recaptchaToken: payload.recaptchaToken });
};

const getOrderReload = payload => {
  const baseUrl = `/api/orders/${payload.pedido}/${payload.empresa}/${payload.filial}`;

  return apiClient.get(`${baseUrl}`);
};

const getDocuments = ({ orderNum, cpfCnpj }) => {
  const baseUrl = `/api/portlet/documentos/${orderNum}/cpfcnpj/${cpfCnpj}`;
  return apiClient.get(`${baseUrl}`);
};

const getPayments = ({ orderNum, cpfCnpj }) => {
  const baseUrl = `/api/portlet/pagamentos/${orderNum}/cpfcnpj/${cpfCnpj}`;
  return apiClient.get(`${baseUrl}`);
};

const getGfe = ({ orderNum, cpfCnpj, Orcamento }) => {
  const baseUrl = `/api/portlet/gfe/pedido/${orderNum}/cpf/${cpfCnpj}/orcamento/${Orcamento}`;
  return apiClient2.get(`${baseUrl}`);
};

const getSlips = ({numNota, empresa}) => {
  const params = {
    numNota: numNota,
    empresa: empresa
  };

  const baseUrl = `/api/orders/slips`;
  return apiClient.get(`${baseUrl}`, params);
};

const getProducts = ({ code }) => {
  const baseUrl = `/api/portlet/todos-produtos/codcliente/${code}`;
  return apiClient.get(`${baseUrl}`);
};

const getNewOrder = data => {
  const baseUrl = data ? `/api/orders/new?${data}` : `/api/orders/new`;
  return apiClient.get(`${baseUrl}`);
};

const getFilters = filial => {
  const baseUrl = `/api/orders/filters/${filial}`;
  return apiClient.get(`${baseUrl}`);
};

const getCategory = ({ group }) => {
  const baseUrl = `/api/DynamicSelects/categoria/${group.trim()}`;
  return apiClient.get(`${baseUrl}`);
};

const getCiclo = ({ group, category }) => {
  const baseUrl = `/api/DynamicSelects/ciclo/${group.trim()}${
    category.trim() != "" ? "/" + category : ""
  }`;
  return apiClient.get(`${baseUrl}`);
};

const getCapacidade = ({ group, category }) => {
  const baseUrl = `/api/DynamicSelects/capacidade/${group.trim()}${
    category.trim() != "" ? "/" + category : ""
  }`;
  return apiClient.get(`${baseUrl}`);
};

const getVoltagem = ({ group, category }) => {
  const baseUrl = `/api/DynamicSelects/voltagem/${group.trim()}${
    category.trim() != "" ? "/" + category : ""
  }`;
  return apiClient.get(`${baseUrl}`);
};

const getPagto = tabela => {
  const baseUrl = `/api/order/condicaopagto/${tabela['tabela']}`;
  return apiClient.get(`${baseUrl}`);
};

const getOrderProducts = filter => {
  const baseUrl = `/api/orders/produtos`;
  return apiClient.create(`${baseUrl}`, filter);
};

const getProductDetails = sku => {
  const baseUrl = `/api/orders/produtos/attributes/getattributes/${sku}`;
  return apiClient.get(`${baseUrl}`);
};

const getProductsDetails = skuList => {
  const baseUrl = `/api/orders/produtos/attributes/getattributes/products`;
  return apiClient.create(`${baseUrl}`, { productsSku: skuList });
};

const consultaCep = cep => {
  const paramns = {
    cep: cep,
  };
  const baseUrl = `/api/consulta/cep`;
  return apiClient.create(`${baseUrl}`, paramns);
};

let activeRequestToCheckCartRules;
const checkCartRule = async pedido => {

  if (activeRequestToCheckCartRules) {
    activeRequestToCheckCartRules.cancel("Cancelando solicitação anterior");
  }

  const source = axios.CancelToken.source();
  activeRequestToCheckCartRules = source;

  const baseUrl = `/api/cartrules/matchrule`;
  const request = await apiClient.create(`${baseUrl}`, pedido, {
    cancelToken: source?.token
  });

  return request;
};

let lastRequest;
const getFrete = ({
  zipcode,
  valTot,
  company,
  filial,
  date,
  cancelRequestDuplicate = true,
}) => {
  if (cancelRequestDuplicate) {
    // Cancelar todas as solicitações anteriores
    if (lastRequest) {
      lastRequest.cancel("Cancelando solicitação anterior");
    }
  }

  // Criar um novo token para esta solicitação
  const source = axios.CancelToken.source();
  lastRequest = source;
  if (company === "02") {
    const baseUrl = `/api/zipcode/${zipcode.replace(
      /[^0-9]+/g,
      ""
    )}/${company}/${filial}/${valTot}`;
    const obj = { previsao: date };

    return apiClient.create(
      `${baseUrl}`,
      obj,
      cancelRequestDuplicate
        ? {
            cancelToken: source?.token,
          }
        : {}
    );
  } else {
    const baseUrl = `/api/zipcode/${zipcode.replace(
      /[^0-9]+/g,
      ""
    )}/01/${filial}/${valTot}`;

    return apiClient.get(`${baseUrl}`, {
      cancelToken: source.token,
    });
  }
};

const getSaldo = () => {
  const baseUrl = `/api/getsaldocashback`;
  return apiClient.get(`${baseUrl}`);
};

const emitirPedido = pedido => {
  const baseUrl = `/api/orders/store`;
  return apiClient.create(`${baseUrl}`, pedido);
};

const emitirPedidoKits = pedido => {
  const baseUrl = `/api/person-kits/savePersonKit`;
  return apiClient.create(`${baseUrl}`, pedido);
};

const editarPedido = pedido => {
  const baseUrl = `/api/orders/update/${pedido.pedido.CODEMPRESA}/${pedido.pedido.CODFILIAL}/${pedido.id}`;
  return apiClient.create(`${baseUrl}`, pedido.pedido);
};

const storeTriangularShipping = pedido => {
  const baseUrl = `/api/orders/triangular/storeShipping/${pedido.id}`
  return apiClient.createWithFile(`${baseUrl}`, pedido.data)
};

const getCuponsNotUsed = () => {
  const baseUrl = `/api/getcouponsnotused`;
  return apiClient.get(`${baseUrl}`);
};

const verificarCupom = cupom => {
  let data = { code: cupom };
  const baseUrl = `/api/verifycoupon`;
  return apiClient.create(`${baseUrl}`, data);
};

const cancelOrder = ({ pedido, filial, empresa, motivo }) => {
  const baseUrl = `/api/orders/cancel/${pedido}/${motivo}/${empresa}/${filial}`;
  return apiClient.get(`${baseUrl}`);
};

const getArquitetos = () => {
  const baseUrl = `/api/partners/getarchitects`;
  return apiClient.get(`${baseUrl}`);
};

const addArquiteto = data => {
  const baseUrl = `/api/partners/save`;
  return apiClient.create(`${baseUrl}`, data);
};

const verifyEmailExists = data => {
  return apiClient.create("/api/solar/isclient", data);
};

const saveProspectClient = data => {
  return apiClient.create("/api/orders/saveprospectclient", data);
};

const getProductsBySku = ({ sku, tabela, pagto, isSolar }) => {
  let data = {
    CODEMPRESA: isSolar ? "02" : "01",
    CODFILIAL: "02",
    condpagto: pagto,
    tabela_preco: tabela,
    sku: sku,
    Nomecondpagto: "",
    Nometabela_preco: "",
    bitolaliq: "",
    bitolasuc: "",
    capacidade: "",
    categoria: "",
    ciclo: "",
    codfabric: "",
    condpagto_limit: "",
    descricao: "",
    estoque: "",
    fabricante: "",
    grupos: "",
    voltagem: "",
  };
  const baseUrl = `/api/orders/produtos`;
  return apiClient.create(`${baseUrl}`, data);
};

export {
  getVendedor,
  getOrderReload,
  getOrdersList,
  getOrder,
  getOrderForCustomer,
  getDocuments,
  getPayments,
  getGfe,
  getSlips,
  getProducts,
  getNewOrder,
  getFilters,
  getCategory,
  getCiclo,
  getCapacidade,
  getVoltagem,
  getPagto,
  getOrderProducts,
  getProductDetails,
  getProductsDetails,
  getFrete,
  consultaCep,
  getSaldo,
  emitirPedido,
  editarPedido,
  storeTriangularShipping,
  getCuponsNotUsed,
  verificarCupom,
  checkCartRule,
  cancelOrder,
  getArquitetos,
  addArquiteto,
  getProductsBySku,
  verifyEmailExists,
  saveProspectClient,
  emitirPedidoKits,
};
