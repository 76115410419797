import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom/cjs/react-router-dom.min";
import { useHistory } from "react-router-dom";
import { Button, Container, Spinner } from "reactstrap";
import TransportHistory from "./TransportHistory";
import StatusHistory from "./StatusHistory";
import Summary from "./Summary";
import "./OrderPublicTrackingDetails.css";
import { getOrderForCustomer } from "../../../../store/actions";
import { useGoogleReCaptcha } from "react-google-recaptcha-v3";
import { toast } from "react-toastify";

const PublicOrderTrackingDetails = () => {
  const history = useHistory();
  const [captchaValidated, setCaptchaValidated] = useState(false);
  const [recaptchaToken, setRecaptchaToken] = useState('');

  const { order, loading, success, errorRequest } = useSelector((state) => ({
    loading: state.OrderPublicTracking.loading,
    success: state.OrderPublicTracking.success,
    order: state.OrderPublicTracking.order,
    errorRequest: state.OrderPublicTracking.error,
  }));

  const { orderId, cpfCnpjCustomer } = useParams();
  const dispatch = useDispatch();
  const { executeRecaptcha } = useGoogleReCaptcha();

  useEffect(() => {
    const handleRecaptcha = async () => {
      if (executeRecaptcha) {
        try {
          const token = await executeRecaptcha("submit");
          setRecaptchaToken(token);
          if (token) {
            setCaptchaValidated(true);
          }
        } catch (error) {
          setRecaptchaToken('');
          setCaptchaValidated(false);
          toast.error('Identificamos um erro na validação CAPTCHA, tente novamente mais tarde.')
        }
      }
    };

    if (!captchaValidated) {
      handleRecaptcha();
    }
  }, [executeRecaptcha, captchaValidated]);

  useEffect(() => {
    if (captchaValidated && orderId && cpfCnpjCustomer) {
      dispatch(getOrderForCustomer(orderId, cpfCnpjCustomer, recaptchaToken));
    }
  }, [dispatch, orderId, cpfCnpjCustomer, captchaValidated, recaptchaToken]);

  const goToOrderSearch = () => {
    history.push("/order/public-tracking");
  };

  useEffect(() => {
    if (errorRequest) {
      goToOrderSearch();
    }
  }, [loading, errorRequest]);

  return (
    <div className="page-content order-details">
      <Container>
        {!captchaValidated && (
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
              justifyContent: "center",
              height: "100vh",
              textAlign: "center",
            }}
          >
            <a style={{ marginTop: "16px", fontSize: "18px", color: "#5A6A79" }}>
              Verificando o CAPTCHA, por favor, aguarde...
            </a>
          </div>
        )}
        {loading && captchaValidated && (
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
              justifyContent: "center",
              height: "100vh",
              textAlign: "center",
            }}
          >
            <Spinner className="mt-4" />
            <a style={{ marginTop: "16px", fontSize: "18px", color: "#5A6A79" }}>
              Carregando detalhes do pedido...
            </a>
          </div>
        )}
        {success && captchaValidated && (
          <>
            <div className="titulos">
              <h2 className="numero-pedido">Pedido #{order?.NumPedido || 0}</h2>
            </div>
            <TransportHistory gfeOcorrencias={order?.gfeOcorrencias} />
            <StatusHistory order={order} />
            <Summary order={order} />
            <Button onClick={() => goToOrderSearch()} className="mt-2 button-voltar">
              BUSCAR OUTRO PEDIDO
            </Button>
          </>
        )}
      </Container>
    </div>
  );
};

export default PublicOrderTrackingDetails;
