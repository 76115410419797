import { OrderPublicTrackingActionTypes } from "./actionTypes";

export const orderPublicTrackingApiSuccess = (actionType, data) => ({
  type: OrderPublicTrackingActionTypes.API_RESPONSE_SUCCESS,
  payload: { actionType, data },
});

export const orderPublicTrackingApiFail = (actionType, error) => ({
  type: OrderPublicTrackingActionTypes.API_RESPONSE_ERROR,
  payload: { actionType, error },
});

export const getOrderForCustomer = (orderNum, customerCpfCnpj, recaptchaToken) => ({
  type: OrderPublicTrackingActionTypes.GET_ORDER_FOR_CUSTOMER,
  payload: { orderNum, customerCpfCnpj, recaptchaToken },
});