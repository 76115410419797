import React from "react"
import { Alert, Card, CardBody, CardHeader, Col, Row } from "reactstrap"
import { formatInvertDate } from "../../../../utils";
import { useColors } from "../../../../hooks/useColors";

const TransportHistory = ({ gfeOcorrencias }) => {
  const { colorDefault } = useColors();

  return <>
    <Card>
      <CardHeader tag="h5" className="font-size-18 text-white" style={{ backgroundColor: '#2D567B' }}>
        <i className="fas fa-truck" style={{ marginRight: '8px' }} />Histórico de transporte
      </CardHeader>
      <CardBody>
        <Row className="p-1">
          <Col>
            <div className="historico-transporte">
              {gfeOcorrencias?.length > 0 ? (
                <>
                  {(gfeOcorrencias || []).map(value => (
                    <div className="nodeTransporte" key={value.GWD_NROCO}>
                      <p style={{ marginBottom: 0 }}>
                        <strong>{value.GU5_DESC}</strong>
                      </p>
                      <p
                        className="text-bold w-75 text-opacity-75 fw-bolder mb-1"
                        style={{ marginBottom: 0, fontSize: 12, color: colorDefault, textTransform: 'uppercase' }}
                      >
                        {value.GWD_DSSOLU}
                      </p>
                      <p style={{ marginBottom: 0 }}>
                        {formatInvertDate(value.GWD_DTOCOR)} - {value.GWD_HROCOR}
                      </p>
                    </div>
                  ))}
                </>
              ) : (
                <Alert color="info">Nenhum histórico de transporte encontrado</Alert>
              )}
            </div>
          </Col>
        </Row>
      </CardBody>
    </Card>
  </>
}

export default TransportHistory;