import React from "react";
import { Card, CardBody, CardHeader, Col, Row } from "reactstrap";

const Summary = ({ order }) => {

  const format = {
    minimumFractionDigits: 2,
    style: "currency",
    currency: "BRL",
  };

  const total = Number(order?.total || "").toLocaleString(
    "pt-br",
    format
  );

  const ColumnCard = ({ title, content }) => {
    return <Row className="mb-2">
      <Col>
        <p className=" texto-dados texto-cliente">
          <span className="fw-bold me-2">{title}: </span>
          {content}
        </p>
      </Col>
    </Row>
  }

  return (
    <div>
      <Card>
        <CardHeader tag="h5" className="font-size-18 text-white" style={{ backgroundColor: '#2D567B' }}>
          <i className="fas fa-file-alt" style={{ marginRight: '8px' }} />{`Dados do pedido`}
        </CardHeader>
        <CardBody>
          <div>
            <ColumnCard title='Código do cliente' content={order?.Cliente} />
            <ColumnCard title='Nome' content={order?.cliente?.N_COMPLETO} />
            <ColumnCard title='CPF/CNPJ' content={order?.cliente?.CPFCNPJ} />
            <ColumnCard title='E-mail' content={order?.cliente?.EMAIL} />
            <ColumnCard title='Data do pedido' content={order?.Data} />
            <ColumnCard title='Condição de pagamento' content={order?.payment_method?.E4_DESCRI} />
            <ColumnCard title='Parcelas' content={order?.parcelas} />
            <ColumnCard title='Valor total' content={total} />
          </div>
        </CardBody>
      </Card>
    </div>
  );
};

export default Summary;