import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Input, Card, CardBody, Col, Row, Spinner, Alert } from "reactstrap";
import AddedItem from "../AddedItem";
import { removeFromCartKits } from "../../../../store/actions";
import ModalInsertProduct from "../ModalInsertProduct";
import { formato } from "../../../../utils";
import "./style.js";
import "../newOrder.css";
import { toast } from "react-toastify";
import { removeFromCart } from "../../../../store/actions";
import { Container } from "./style.js";
import { isInverter } from "../../../utils/functions/solarUtils.js";

const Cart = ({
  addValTot,
  valores,
  loadingSearModule,
  setPercentWattpay,
  valorRt,
  addValorRt,
  valArquiteto,
  ocultarRt,
  addValArqu,
  setItensCarrinho,
  itensCarrinho,
  percentCashBackAll,
  setPercentCashBackAll,
  setPercentCashBackAllOnBlur,
  percentCashBackAllOnBlur,
  realPower,
  setRealPower,
  setValTotAndRt,
  valTotAndRt,
  moduleSelected,
  acrescimo,
  changeFormOfPayment,
  addValorRtNoRetention,
  subTotalDiscount,
  total,
  isMicroInverterSelected,
  valoresTabela,
  addValoresTabela,
  itHasForecast,
  triangularSale,
  removeFromCartState,
}) => {
  const { newOrder, vendedor } = useSelector(state => {
    return {
      newOrder: state.Order.newOrder.newOrder,
      vendedor: state.Order.newOrder.newOrder.vendedor,
    };
  });

  const dispatch = useDispatch();

  const [isOpeModalInsertProduct, setIsOpenModalInsertProduct] =
    useState(false);
  const openModal = () => {
    setIsOpenModalInsertProduct(true);
  };
  const totalPrecoUniCliFinal = newOrder.addedItems.reduce(
    (previousValue, currentValue) =>
      previousValue + currentValue.produto.precoCliente,
    0
  );

  const [loadingProductAddition, setLoadingProductAddition] = useState(false);

  const realPowerFormat = Intl.NumberFormat("pt-BR").format(realPower);

  const handleAllCashBack = event => {
    var value = event.target.value;

    if (parseInt(value) < 0 || parseInt(value) > 50) {
      toast.warning(`A % deve estar entre 0% e 50%`, { theme: "colored" });
    } else {
      setPercentCashBackAll(event.target.value);
    }
  };

  const handleAllCashBackBlur = () => {
    setPercentCashBackAllOnBlur(state => !state);
  };

  useEffect(() => {
    addValTot(valTotAndRt);
  }, [valTotAndRt]);

  useEffect(() => {
    if (changeFormOfPayment) {
      setPercentCashBackAll("0");
    }
  }, [changeFormOfPayment]);

  const totalWithDiscountApplied = total - subTotalDiscount;

  useEffect(() => {
    if (removeFromCartState) {
      const moduleKeywords = ["MOD.", "Modulos", "MOD FOTOV", "MOD. FOTOV", "GCL"];
  
      const removeLinkedPartsValTotal = (productItem) => {
        let valTotal = valores;
        delete valTotal[
          productItem?.id
            ? productItem?.id + " " + productItem?.nome
            : productItem.nome
        ];
        addValTot(valTotal);
        addValTotal();
      };
  
      const removeStringBoxAndMC4 = (item) => {
        item?.pecasVinculadas?.forEach((peca) => {
          newOrder.addedItems.forEach((product) => {
            if (product.produto[0].sku === peca.sku) {
              const productItem = product.produto[0];
              dispatch(removeFromCartKits({ produto: productItem }));
              removeLinkedPartsValTotal(productItem);
            }
          });
        });
      };
  
      const addValTotal = (item) => {
        let valTotal = valores;
        delete valTotal[item?.produto?.[0].id ? item?.produto?.[0].id + " " + item?.produto?.[0].nome : item?.produto?.[0].nome];
        addValTot(valTotal);
      };
  
      Object.values(newOrder.addedItems).forEach((item) => {
        const thereIsAModuleInTeCart = moduleKeywords.some(keyword => item?.produto?.[0].nome?.includes(keyword));
  
        if (thereIsAModuleInTeCart) {
          toast.warning(
            `Entre em contato com o comercial para realizar a compra de itens fora do Kit. Os valores podem sofrer alterações`,
            { theme: "colored" }
          );
        }
  
        if (Object.keys(newOrder.addedItems).length > 1) {
          if (isInverter(item?.produto?.[0])) {
            removeStringBoxAndMC4();
          } else {
            addValTotal();
          }
  
          dispatch(removeFromCartKits({ produto: item.produto[0] }));
  
        } else {
          toast.info("Não é possível remover todos os itens", { theme: "colored" });
        }
      });
    }
  }, [removeFromCartState]);
  

  return (
    <Container ItHasForecast={itHasForecast}>
      <Col className="cart">
        {loadingSearModule ? (
          <>
            {" "}
            <h4
              className="mb-4"
              style={{
                fontSize: 18,
                fontWeight: "bold",
                color: "#FF601A",
              }}
            >
              Produtos Adicionados
            </h4>{" "}
            <div
              style={{
                height: 100,
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <Spinner />
            </div>
          </>
        ) : (
          <>
            {!loadingSearModule && (
              <>
                <h4
                  className="mb-4"
                  style={{
                    fontSize: 18,
                    fontWeight: "bold",
                    color: "#FF601A",
                  }}
                >
                  Produtos Adicionados
                </h4>

                <Card>
                  <CardBody>
                    <Row className="position-relative" style={{ zIndex: 100 }}>
                      <Col
                        md={8}
                        style={{
                          display: "flex",
                          alignItems: "center",
                          gap: 20,
                        }}
                      >
                        <button
                          onClick={() => setIsOpenModalInsertProduct(true)}
                          style={{
                            background: "#FF601A",
                            color: "#fff",
                            width: "fit-content",
                          }}
                          className="btn button-module"
                          disabled={loadingProductAddition}
                        >
                          Inserir Produto
                        </button>
                        <span
                          style={{
                            color: "#495057",
                            fontWeight: "bold",
                            display: "flex",
                            alignItems: "center",
                            gap: 5,
                            fontSize: 17,
                          }}
                        >
                          Potência:
                          <p
                            style={{
                              margin: 0,
                              fontWeight: "normal",
                              fontSize: 16,
                            }}
                          >
                            {realPowerFormat}
                          </p>
                        </span>
                      </Col>{" "}
                      <Col md={4}>
                        <div className="product-info" hidden={ocultarRt}>
                          <p
                            style={{
                              marginBottom: 3,
                              textAlign: "center",
                              overflow: "hidden",
                              textOverflow: "ellipsis",
                              whiteSpace: "nowrap",
                            }}
                            data-toggle="tooltip"
                            data-placement="top"
                            title="Adicionar % de CashBack para todos os produtos"
                          >
                            Adicionar % de CashBack para todos os produtos
                          </p>
                          <Input
                            className="mb-0 mt-4 pre-venda"
                            style={{ maxWidth: 170 }}
                            value={percentCashBackAll.replace(/[^\d.,]/g, "")}
                            onChange={handleAllCashBack}
                            onBlur={handleAllCashBackBlur}
                            readOnly={vendedor?.VENDEDOR?.TEMRT != 1 || triangularSale}
                          />
                        </div>
                      </Col>
                    </Row>

                    <Row
                      className="position-relative"
                      style={{ marginLeft: 30, marginRight: 30, marginTop: 20, zIndex: 100 }}
                    >
                      <Col
                        style={{ padding: 0 }}
                        md={6}
                      >
                        PRODUTO
                      </Col>
                      <Col style={{ padding: 0, textAlign: "center" }} md={2}>
                        DATA SHEET
                      </Col>
                      <Col style={{ padding: 0 }} md={1}>
                        QTDE
                      </Col>
                      <Col style={{ padding: 0 }} md={2}>
                        PREVISÃO DISP.
                      </Col>
                      <Col md={1}>AÇÕES</Col>
                    </Row>

                    <div className="box-cart">
                      {/* Mapeia os itens adicionados */}
                      {newOrder.addedItems?.map((item, k) => (
                        <AddedItem
                          key={k}
                          item={item}
                          addValTot={addValTot}
                          valores={valores}
                          loadingSearModule={loadingSearModule}
                          setItensCarrinho={setItensCarrinho}
                          setPercentWattpay={setPercentWattpay}
                          itensCarrinho={itensCarrinho}
                          valArquiteto={valArquiteto}
                          addValArqu={addValArqu}
                          valorRt={valorRt}
                          addValorRt={addValorRt}
                          ocultarRt={ocultarRt}
                          percentCashBackAll={percentCashBackAll}
                          setPercentCashBackAll={setPercentCashBackAll}
                          percentCashBackAllOnBlur={percentCashBackAllOnBlur}
                          setValTotAndRt={setValTotAndRt}
                          acrescimo={acrescimo}
                          changeFormOfPayment={changeFormOfPayment}
                          itHasForecast={itHasForecast}
                          addValorRtNoRetention={addValorRtNoRetention}
                          setRealPower={setRealPower}
                          valoresTabela={valoresTabela}
                          addValoresTabela={addValoresTabela}
                        />
                      ))}
                      {/* Renderiza o componente de loading condicionalmente */}
                      {loadingProductAddition && (
                        <div
                          style={{
                            position: "absolute",
                            top: 0,
                            left: 0,
                            width: "100%",
                            height: "100%",
                            backgroundColor: "rgba(255, 255, 255, 0.8)",
                            display: "flex",
                            justifyContent: "center",
                            alignItems: "center",
                            zIndex: 1
                          }}
                        >
                          <Spinner />
                        </div>
                      )}
                    </div>
                    <Card
                      style={{
                        borderRadius: "0 0  15px 15px",
                        backgroundColor: "#FFF7F2",
                        zIndex: 100,
                        marginTop: 20,
                      }}
                    >
                      <CardBody>
                        <Row>
                          <Col style={{ color: "#FF601A", display: "flex", alignItems: "center" }} md={3}>
                            TOTAL DO ORÇAMENTO
                          </Col>
                          {/*     <Col style={{ color: "#FF601A" }} md={2}>
                            -
                          </Col> */}
                          <Col style={{ color: "#FF601A", display: "flex", alignItems: "center" }} md={3}>
                            -
                          </Col>
                          {/*     <Col style={{ color: "#FF601A" }} md={2}>
                            -
                          </Col> */}{" "}
                          <Col md={3}></Col>
                          <Col>
                            {subTotalDiscount > 0 ? (
                              <>
                                <Col style={{ color: "#FF601A" }} md={6}>
                                  <del>{total.toLocaleString("pt-br", formato)}</del>
                                </Col>{" "}
                                <Col style={{ color: "#FF601A" }} md={6}>
                                  {totalWithDiscountApplied.toLocaleString("pt-br", formato)}
                                </Col>{" "}
                              </>
                            ) : (
                              <>
                                <Col style={{ color: "#FF601A" }} md={6}>
                                  {total.toLocaleString("pt-br", formato)}
                                </Col>{" "}
                              </>
                            )}
                          </Col>
                        </Row>
                      </CardBody>
                    </Card>
                  </CardBody>
                </Card>
              </>
            )}
          </>
        )}
      </Col>
      <ModalInsertProduct
        isOpeModalInsertProduct={isOpeModalInsertProduct}
        setIsOpenModalInsertProduct={setIsOpenModalInsertProduct}
        addValTot={addValTot}
        valores={valores}
        moduleSelected={moduleSelected}
        acrescimo={acrescimo}
        isMicroInverterSelected={isMicroInverterSelected}
        setLoadingProductAddition={setLoadingProductAddition}
      />
    </Container>
  );
};

export default Cart;
