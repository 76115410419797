import { OrderPublicTrackingActionTypes } from "./actionTypes"

const INIT_STATE = {
  loading: false,
  order: false,
  success: false,
  error: false
}

const OrderPublicTracking = (state = INIT_STATE, action) => {
  switch (action.type) {
    case OrderPublicTrackingActionTypes.API_RESPONSE_SUCCESS:
      switch (action.payload.actionType) {
        case OrderPublicTrackingActionTypes.GET_ORDER_FOR_CUSTOMER:
          return {
            loading: false,
            order: action.payload.data.order,
            success: action.payload.data.success,
            error: false
          }
        default:
          return state;
      }

      break;
    case OrderPublicTrackingActionTypes.API_RESPONSE_ERROR:
      switch (action.payload.actionType) {
        case OrderPublicTrackingActionTypes.GET_ORDER_FOR_CUSTOMER:
          return {
            loading: false,
            order: false,
            success: false,
            error: true
          }
        default:
          return state;
      }

      break;

    case OrderPublicTrackingActionTypes.GET_ORDER_FOR_CUSTOMER:
      return {
        loading: true,
        order: false,
        success: false,
        error: false
      }
    default:
      return state;
  }
}

export default OrderPublicTracking;