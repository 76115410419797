import { toast } from "react-toastify";
import { OrderPublicTrackingActionTypes } from "./actionTypes";
import { orderPublicTrackingApiFail, orderPublicTrackingApiSuccess } from "../actions";
import { all, call, fork, put, takeEvery } from "redux-saga/effects";
import { getOrderForCustomer as getOrderForCustomerApi } from "../../api/order";

function* getOrderForCustomer({ payload: { orderNum, customerCpfCnpj, recaptchaToken } }) {
  try {
    const response = yield call(getOrderForCustomerApi, { orderNum, customerCpfCnpj, recaptchaToken });
    if (response.order) {
      yield put(orderPublicTrackingApiSuccess(OrderPublicTrackingActionTypes.GET_ORDER_FOR_CUSTOMER, response));
    } else {
      toast.error(`Não foi possível carrregar seu pedido. Tente novamente!`, {
        theme: "colored",
      });
      yield put(orderPublicTrackingApiSuccess(OrderPublicTrackingActionTypes.GET_ORDER_FOR_CUSTOMER, 'Não foi possível carrregar seu pedido. Tente novamente!'));
    }
  } catch (error) {
    toast.error(`Não foi possível carrregar seu pedido. Tente novamente!`, {
      theme: "colored",
    });
    yield put(orderPublicTrackingApiFail(OrderPublicTrackingActionTypes.GET_ORDER_FOR_CUSTOMER, error));
  }
}

export function* watchGetOrderForCustomer() {
  yield takeEvery(OrderPublicTrackingActionTypes.GET_ORDER_FOR_CUSTOMER, getOrderForCustomer);
}

function* orderPublicTrackingSaga() {
  yield all([
    fork(watchGetOrderForCustomer),
  ]);
}

export default orderPublicTrackingSaga;
