import React, { useEffect, useState } from "react";
import {
  Card,
  CardBody,
  Col,
  Row,
  CardHeader,
} from "reactstrap";
import SvgIcon from "../../../../components/SvgIcon";
import { formatInvertDate } from "../../../../utils";
import './OrderPublicTrackingDetails.css'

const StatusHistory = ({ order }) => {
  const valores = {
    A: 1,
    B: 2,
    C: 3,
    D: 4,
    E: 5,
    S: 6,
    F: 7,
    G: 8,
    H: 9,
    I: 10,
    J: 11,
    K: 12,
    L: 13,
  };

  const isSolar = () => order?.CodFilial == '02';

  function selectDataFase(valor) {
    var situacaoAtual = order?.Situacao;

    if (
      ((situacaoAtual == "J" && valor == "J") ||
        (situacaoAtual == "H" && valor == "J")) &&
      order?.entrega
    ) {
      return <p dangerouslySetInnerHTML={{ __html: order.entrega }} />;
    }

    const lastEntry = getLastStatus(valor);

    if (!lastEntry) {
      if (order?.gfe) {
        if (valor == "H") {
          return getStatusExbition(
            formatInvertDate(order.gfe.GW1_DTSAI),
            order.gfe.GW1_HRSAI
          );
        }
      }

      if (
        (situacaoAtual == "K" && valor == "J") ||
        (situacaoAtual == "L" && valor == "J")
      ) {
        return getStatusExbition(
          formatInvertDate(
            order.fila_historico[order.fila_historico.length - 1].dtent
          ),
          order.fila_historico[order.fila_historico.length - 1].hrent
        );
      }

      if (valores[valor] < valores[situacaoAtual]) {
        return formatInvertDate(order.DataEmissao);
      }

      return null;
    }

    return getStatusExbition(
      formatInvertDate(lastEntry?.dtsai?.trim() != "" ? lastEntry.dtsai : lastEntry.dtent),
      lastEntry?.hrsai?.trim() != "" ? lastEntry.hrsai : lastEntry.hrent
    );
  }

  const getLastStatus = valor => {
    const filteredHistoryByStatus = order?.fila_historico?.filter(item => item.situacao === valor) || [];

    if (!filteredHistoryByStatus || filteredHistoryByStatus.length === 0) {
      return null;
    }

    let sortedHistoryByLastDate = filteredHistoryByStatus[0];

    filteredHistoryByStatus.forEach(status => {
      const statusDate = status.dtsai ? status.dtsai : status.dtent;
      const statusTime = status.hrsai ? status.hrsai : status.hrent;

      const sortedHistoryDate = sortedHistoryByLastDate.dtsai ? sortedHistoryByLastDate.dtsai : sortedHistoryByLastDate.dtent;
      const sortedHistoryTime = sortedHistoryByLastDate.hrsai ? sortedHistoryByLastDate.hrsai : sortedHistoryByLastDate.hrent;

      if (statusDate > sortedHistoryDate || (statusDate == sortedHistoryDate && statusTime > sortedHistoryTime)) {
        sortedHistoryByLastDate = status;
      }
    });

    return sortedHistoryByLastDate;
  }

  const getStatusExbition = (date, hour) => {
    return (<>
      <p className="text-muted mb-0 font-size-13">
        {date}
      </p>
      <p className="text-muted mb-0 font-size-13">
        {hour}
      </p>
    </>)
  }

  return (
    <React.Fragment>
      <Card>
        <CardHeader tag="h5" className="font-size-18 text-white" style={{ backgroundColor: '#2D567B' }}>
          <i className="fas fa-tasks" style={{ marginRight: '8px' }} />Status
        </CardHeader>
        <CardBody>
          <Row className="mb-4">
            <Col lg={12}>
              <div className="py-3">
                <ul className="list-unstyled track-order-list flex-wrap">
                  <li
                    className={valores[order?.Situacao] > 1 ? "completed" : ""}
                  >
                    <div className="order-icon mb-3">
                      <SvgIcon
                        name="clipboard-text-2"
                        className="icon icon-lg display-4"
                      />
                    </div>
                    <div className="progress-line">
                      <i className="mdi mdi-circle-outline text-white check-icon" style={{ backgroundColor: "#13b013" }}></i>
                    </div>
                    <div className="overflow-hidden mt-4">
                      <span className="step-title">Análise do Pedido</span>
                      <div>
                        <p className="text-muted mb-0 font-size-13">
                          {selectDataFase("A")}
                        </p>
                      </div>
                    </div>
                  </li>
                  <li
                    className={valores[order?.Situacao] > 4 ? "completed" : ""}
                  >
                    <div className="order-icon mb-3">
                      <SvgIcon
                        name="credit-card-2"
                        className="icon icon-lg display-4"
                      />
                    </div>
                    <div className="progress-line">
                      <i className="mdi mdi-circle-outline check-icon"></i>
                    </div>
                    <div className="overflow-hidden mt-4">
                      <span className="step-title">Analise de Crédito</span>
                      <div>
                        <p className="text-muted mb-0 font-size-13">
                          {selectDataFase("C")}
                        </p>
                      </div>
                    </div>
                  </li>
                  {isSolar && (
                    <li
                      className={valores[order?.Situacao] > 6 ? "completed" : ""}
                    >
                      <div className="order-icon mb-3">
                        <SvgIcon
                          name="cursor-grab"
                          className="icon icon-lg display-4"
                        />
                      </div>
                      <div className="progress-line">
                        <i className="mdi mdi-circle-outline check-icon"></i>
                      </div>
                      <div className="overflow-hidden mt-4">
                        <span className="step-title">Em produção</span>
                        <div>
                          <p className="text-muted mb-0 font-size-13">
                            {selectDataFase("S") ? selectDataFase("S") : selectDataFase("F")}
                          </p>
                        </div>
                      </div>
                    </li>
                  )}
                  <li
                    className={valores[order?.Situacao] > 7 ? "completed" : ""}
                  >
                    <div className="order-icon mb-3">
                      <SvgIcon
                        name="file-text-2"
                        className="icon icon-lg display-4"
                      />
                    </div>
                    <div className="progress-line">
                      <i className="mdi mdi-circle-outline check-icon"></i>
                    </div>
                    <div className="overflow-hidden mt-4">
                      <span className="step-title">Faturamento</span>
                      <div>
                        <p className="text-muted mb-0 font-size-13">
                          {selectDataFase("F")}
                        </p>
                      </div>
                    </div>
                  </li>
                  <li
                    className={valores[order?.Situacao] > 8 ? "completed" : ""}
                  >
                    <div className="order-icon mb-3">
                      <SvgIcon
                        name="shipment"
                        className="icon icon-lg display-4"
                      />
                    </div>
                    <div className="progress-line">
                      <i className="mdi mdi-circle-outline check-icon"></i>
                    </div>
                    <div className="overflow-hidden mt-4">
                      <span className="step-title">Expedição</span>
                      <div>
                        <p className="text-muted mb-0 font-size-13">
                          {selectDataFase("G")}
                        </p>
                      </div>
                    </div>
                  </li>
                  <li
                    className={valores[order?.Situacao] > 9 ? "completed" : ""}
                  >
                    <div className="order-icon mb-3">
                      <SvgIcon
                        name="truck-2"
                        className="icon icon-lg display-4"
                      />
                    </div>
                    <div className="progress-line">
                      <i className="mdi mdi-circle-outline check-icon"></i>
                    </div>
                    <div className="overflow-hidden mt-4">
                      <span className="step-title">Em Trânsito</span>
                      <div>
                        <p className="text-muted mb-0 font-size-13">
                          {selectDataFase("H")}
                        </p>
                      </div>
                    </div>
                  </li>
                  <li
                    className={
                      valores[order?.Situacao] >= 11 ? "completed" : ""
                    }
                  >
                    <div className="order-icon mb-3">
                      <SvgIcon
                        name="package-2"
                        className="icon icon-lg display-4"
                      />
                    </div>
                    <div className="progress-line" style={{ flexGrow: 0 }}>
                      <i className="mdi mdi-circle-outline check-icon"></i>
                    </div>
                    <div className="overflow-hidden mt-4">
                      <span className="step-title">
                        {order?.Situacao == "L"
                          ? "Devolução Total"
                          : order?.Situacao == "K"
                            ? "Devolução Parcial"
                            : order?.Situacao == "H"
                              ? "Previsão de Entrega"
                              : "Entregue"}
                      </span>
                      <div>
                        <p className="text-muted mb-0 font-size-13">
                          {selectDataFase("J")}
                        </p>
                      </div>
                    </div>
                  </li>
                </ul>
              </div>
            </Col>
          </Row>
        </CardBody>
      </Card>
    </React.Fragment>
  );
};

export default StatusHistory;
