import React, { useEffect, useState } from 'react';
import { TextField, Button, Box, Typography, Paper, Alert, Container } from '@mui/material';
import InputAdornment from '@mui/material/InputAdornment';
import AccountCircle from '@mui/icons-material/AccountCircle';
import ConfirmationNumberIcon from '@mui/icons-material/ConfirmationNumber';
import { useHistory } from 'react-router-dom/cjs/react-router-dom';

const PublicOrderTracking = () => {
  const history = useHistory();

  const [orderNumber, setOrderNumber] = useState('');
  const [cpfCnpj, setCpfCnpj] = useState('');
  const [error, setError] = useState('');

  const handleCpfCnpjChange = (e) => {
    let value = e.target.value.replace(/\D/g, '');

    if (value.length > 14) return; 

    if (value.length <= 11) {
      value = value.replace(/(\d{3})(\d{3})(\d{3})(\d{1,2})?/, '$1.$2.$3-$4');
    } else {
      value = value.replace(/(\d{2})(\d{3})(\d{3})(\d{4})(\d{1,2})?/, '$1.$2.$3/$4-$5');
    }

    setCpfCnpj(value);
  };

  const handleSubmit = (e) => {
    e.preventDefault();

    if (!orderNumber || !cpfCnpj) {
      setError('Por favor, preencha ambos os campos.');
      return;
    }

    const cpfCnpjLimpo = cpfCnpj.replace(/\D/g, '');
    history.push(`/order/public-tracking/${orderNumber}/${cpfCnpjLimpo}`);
  };


  return (
    <>
      <Box
        sx={{
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          minHeight: '100vh',
          backgroundColor: '#E0E6ED',
        }}
      >
        <Container maxWidth="sm">
          <Paper
            sx={{
              padding: 5,
              borderRadius: 4,
              backgroundColor: '#F8FAFC',
              boxShadow: '0px 4px 12px rgba(45, 86, 123, 0.2)',
            }}
          >
            <Typography
              variant="h5"
              align="center"
              gutterBottom
              sx={{ color: '#2D567B', fontWeight: 'bold' }}
            >
              Buscar Pedido
            </Typography>
            <Typography
              variant="body2"
              align="center"
              gutterBottom
              sx={{ color: '#5A6A79' }}
            >
              Informe o número do pedido e o CPF/CNPJ utilizado na compra.
            </Typography>

            {error && (
              <Alert severity="error" sx={{ marginBottom: 2 }}>
                {error}
              </Alert>
            )}

            <form onSubmit={handleSubmit}>
              <TextField
                fullWidth
                margin="normal"
                label="Número do Pedido"
                placeholder="Somente números"
                value={orderNumber}
                onChange={(e) => setOrderNumber(e.target.value)}
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">
                      <ConfirmationNumberIcon />
                    </InputAdornment>
                  ),
                }}
              />
              <TextField
                fullWidth
                margin="normal"
                label="CPF/CNPJ"
                placeholder="Somente números"
                value={cpfCnpj}
                onChange={handleCpfCnpjChange}
                inputProps={{ maxLength: 18 }}
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">
                      <AccountCircle />
                    </InputAdornment>
                  ),
                }}
              />
              <Box sx={{ marginTop: 3 }}>
                <Button
                  type="submit"
                  variant="contained"
                  fullWidth
                  sx={{
                    backgroundColor: '#2D567B',
                    '&:hover': { backgroundColor: '#1B3A57' },
                    padding: 1.5,
                  }}
                >
                  Buscar Pedido
                </Button>
              </Box>
            </form>
          </Paper>
        </Container>
      </Box>
    </>
  );
};

export default PublicOrderTracking;
